import { Col, Container, Row } from "react-bootstrap";
import { useContext } from "react";

import { TranslateContext } from "../../../hooks/context/translateContext";



const Section4 = () => {
    const { translate } = useContext(TranslateContext);
    return (
        <Container fluid className="users-section w-100 vh-100" id="users">
            <h2 className="mb-5 p-2 section-title">{translate('Usuarios')}</h2>
            <div className="mt-3 trades-individual">
            <Row className="p-3 m-l-5 justify-content-end users vh-50 trades">
                <Col xs={12} md={4} className="text-start mx-3">
                    <h2 className="py-1 fw-bold">{translate('Traders profesionales')}</h2>
                    <div dangerouslySetInnerHTML={{ __html:  translate('Gestiona múltiples cuentas con diferentes estrategias, optimizando dinámicamente los parámetros para cada una.').replace(/\n/g, '<br>') }}></div>
                </Col>
            </Row>
            <Row className="users">
                <Col className="text-start pb-5" md={4}>
                    <h2 className="py-2 fw-bold">{translate('Usuarios individuales')}</h2>
                    <div>{translate('Configura el sistema con optimización dinámica por IA para que se adapte a su perfil de riesgo y estrategia de trading.')}</div>
                </Col>
            </Row>
            </div>
        </Container>

    )
}

export default Section4;
import { Stack } from "react-bootstrap";


const General = ({ children }) => {

  return (
    <Stack className=" overflow-hidden mw-100 min-vh-100">
      <div >
        {children}
      </div>
    </Stack>
  )
}

export default General

import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { TranslateContext } from "../../../hooks/context/translateContext";

import Contact from "../../../components/Contanct";

import contactWave from "../../../media/contactWave.svg";

const Section5 = () => {
    const { translate } = useContext(TranslateContext);

    return (
        <Container fluid className="bg-secondary  px-lg-5 py-3 contact-section h-100" id="contact" style={{ backgroundImage: `url(${contactWave})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom' }}>
            <h2 className="mb-5 p-2 section-title">{translate('Contacto')}</h2>
            <Row className="pb-5 mb-5">
                <Col >
                    <Contact />
                </Col>
            </Row>
        </Container>
    )
}

export default Section5;

import { Col, Container, Row } from "react-bootstrap";
import React, { useContext, useEffect } from "react";

import { motion, useAnimation } from "framer-motion";

import { TranslateContext } from "../../../hooks/context/translateContext";



const Header = ({ children }) => {
    const { translate } = useContext(TranslateContext);
    const controls = useAnimation();

    useEffect(() => {
        controls.start({ opacity: 1, transition: { duration: 1 } });
    }, [controls]);

    const AnimatedContent = ({ children }) => {
        return (
          <motion.div
            initial={{ x: -100, opacity: 0 }} 
            animate={{ x: 0, opacity: 1 }} 
            transition={{ duration: 1 }} 
          >
            {children}
          </motion.div>
        );
      };

    return (
        <Container fluid className="px-0 h-100 pb-3 header"  >
            {children}
            <div className="text-center mx-auto d-flex justify-content-center p-5 my-5 d-none d-lg-flex" />
            <Row className="h-100 flex-center text-dark  d-flex justify-content-start introduction">
                <AnimatedContent>
                <Col xs={12} md={6} className="d-flex flex-column h-100 mt-2 p-4">
                    <strong className="h1 text-shadow mt-3">
                        {translate('IA Avanzada para Gestión de Riesgo en Trading Forex')}
                    </strong>
                    <p className="mb-3 fs-6  text-dark  mt-3">
                        <strong>SailApi</strong> {translate('es una inteligencia artificial avanzada que')}
                        <strong> {translate('mejora')}</strong> {translate('la gestión del riesgo en')} <strong>trading</strong> {translate('automatizado de Forex mediante la monitorización dinámica del')} <strong> DrawDown </strong>
                        {translate('y el análisis en tiempo real de datos económicos globales y de mercado, optimizando automáticamente las decisiones de trading para minimizar riesgos y evitar liquidaciones.')}
                    </p>
                </Col>
                </AnimatedContent>
            </Row>
        </Container>
    )
}

export default Header;

import React from "react";
import { Element, scroller } from 'react-scroll';

import Navbar from "../navigation/Navbar";
import Footer from "../navigation/Footer";
import Header from "./home/Header";
import Section1 from "./home/Section1";
import Section2 from "./home/Section2";
import Section3 from "./home/Section3";
import Section4 from "./home/Section4";
import Section5 from "./home/Section5";

const scrollTo = (element) => () => {
    scroller.scrollTo(element, {
        duration: 500,
        smooth: true,
        dynamic: true
    })
}
const links = [
    { text: 'Funcionamiento', handleClick: scrollTo('features') },
    { text: 'Ventajas', handleClick: scrollTo('benefits') },
    { text: 'Precios', handleClick: scrollTo('price') },
    { text: 'Usuarios', handleClick: scrollTo('users') },
    { text: 'Contacto', handleClick: scrollTo('contact') },
  ]

  
const Home = () => {
    return (
        <React.Fragment>
            <Header>
                <Navbar links={links}/>
            </Header>
            <Element name="features"/>
            <Section1 />
            <Element name="benefits"/>
            <Section2 />
            <Element name="price"/>
            <Section3 />
            <Element name="users"/>
            <Section4 />
            <Element name="contact"/>
            <Section5 />
            <Footer links={links} />
        </React.Fragment>
    )
}

export default Home;
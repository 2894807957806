import { createContext, useMemo, useState } from "react";

const TranslateContext = createContext();
const translations = {
  en: "en.json",
  es: "es.json",
};

const TranslateWrapper = ({ children }) => {
  const [lang, setLang] = useState("es");

  const dictionary = useMemo(() => {
    return require("../../translations/" + translations[lang]);
  }, [lang]);
  const handleLang = (lang) => {
    setLang(lang);
    window.scrollTo({ top: 0, behavior: "smooth" });
   // window.location.reload();
  };
  const translate = (text) => {
    return dictionary[text] ? dictionary[text] : text;
  };

  return (
    <TranslateContext.Provider value={{ handleLang, translate }}>
      {children}
    </TranslateContext.Provider>
  );
};

export { TranslateContext, TranslateWrapper };

import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faShieldAlt, faChartArea, faCogs } from '@fortawesome/free-solid-svg-icons';

import { TranslateContext } from "../../../hooks/context/translateContext";


const Section2 = () =>{
    const { translate } = useContext(TranslateContext);

    const benefits = [
        {
            title: translate('Mayor adaptabilidad al mercado'),
            content: (<p>{translate('Se ajusta a las condiciones cambiantes del')} <strong className="text-primary">{translate('mercado')}</strong>, {translate('optimizando su comportamiento para')} <strong className="text-primary">{translate('minimizar')}</strong> {translate('el riesgo en cada momento.')}</p>),
            icon: <FontAwesomeIcon icon={faExchangeAlt}/>
        },
        {
            title: 'Reducción del riesgo de liquidación',
            content: (<p><strong className="text-primary">{translate('Minimiza')}</strong> {translate('el riesgo de que el bróker liquide la cuenta por pérdidas excesivas.')}</p>),
            icon: <FontAwesomeIcon icon={faShieldAlt}/>
        },
        {
            title: 'Mejor control del riesgo',
            content: (<p>{translate('Permite a los')} <strong className="text-primary">{translate('usuarios')}</strong> {translate('establecer un control más preciso del riesgo en sus estrategias de')} <strong className="text-primary">{translate('trading')}</strong>.</p>),
            icon: <FontAwesomeIcon icon={faChartArea}/>
        },
        {
            title: 'Utilización de nuestra exclusiva IA',
            content: (<p><strong className="text-primary">SailApi</strong> {translate('Ofrece una visión más profunda del mercado y optimiza los parámetros de forma ')}<strong className="text-primary">{translate('inteligente')}</strong>.</p>),
            icon: <FontAwesomeIcon icon={faCogs}/>
        },
    ];

    return(
        <Container className="p-5 my-5 d-flex flex-column justify-content-center" id="benefits">
            <h2 className="mb-5 p-2 section-title">{translate('Ventajas')}</h2>
            <Row className="g-5 mx-auto mt-1">
                {benefits.map((benefit,index) =>
                    <Col sm={12} md={6} key={index}>
                    <Row className="flex-column justify-content-between  text-center mx-auto g-2 benefits-cards">
                        <Col className="p-2 mx-auto" style={{width: '2.625rem',height: '2.625rem',borderRadius: '50%',background: 'rgb(197 202 233)'}}>{benefit.icon}</Col>
                        <Col><h4 className="fw-bold ">{benefit.title}</h4></Col>
                        <Col>{benefit.content}</Col> 
                    </Row>
                    </Col>
                )}
            </Row>
        </Container>
    )
}

export default Section2;
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { TranslateContext } from "../../../hooks/context/translateContext";


const Section3 = () => {
    const { translate } = useContext(TranslateContext);

    const subscriptions = [
        {
            title: translate('Suscripción gratuita'),
            contents: [
                'Solo para pruebas o uso personal.',
                'Hasta 2 cuentas comerciales sin cargo.',
                'Autosoporte mediante documentación y ejemplos de código.',
                'Se permite enviar errores y solicitudes de funciones..'
            ]
        },
        {
            title: translate('Suscripción ligera'),
            contents: [
                '7 días de prueba. La prueba se puede activar solo una vez.',
                'Se incluye una cuenta MT gratuita.',
                '48 horas de soporte tiempo de respuesta.',
                'Servidores API compartidos',
                'Acceso a la API de datos históricos del mercado.'
            ]
        }
    ]
    return (
        <Container fluid className="p-5 bg-secondary">
            <h2 className="section-title">{translate('Precios')}</h2>
            <h3 className="text-center p-5">{translate('Suscripciones')}</h3>
            <Container className="mx-auto">
                <Row className="d-flex justify-content-between g-5 p-lg-5">
                    {subscriptions.map((suscription, index) => (
                        <Col xs={12} md={6} className="mx-auto price-card py-3" key={index} >
                            <Row className="card-head">
                                <h3>{translate(suscription.title)}</h3>
                                <div>{translate('Para precios, consulte al contacto')}</div>
                            </Row>
                            <Row className="card-body">
                                {suscription.contents.map((content, index) => (
                                    <Col key={index} md={12} className="py-2 align-items-center"> 
                                    <Row className="p-0 m-0">
                                        <Col xs={1} md={1}>{index + 1}</Col>
                                        <Col xs={11} md={11}>{translate(content)}</Col>
                                    </Row>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    )
}

export default Section3;
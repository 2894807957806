import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Hamburger from "hamburger-react";
import { Collapse } from "reactstrap";

import { motion, useScroll } from "framer-motion";

import { TranslateContext } from "../../hooks/context/translateContext";

import classNames from "classnames";
import { NavLink } from "../../components/shared/NavLink";

import sailapi from "../../media/sailapi.svg";
import logo from "../../media/logo.svg";

const Navbar = ({ links = [] }) => {

  const { scrollY } = useScroll()
  const [isScrolled, setIsScrolled] = useState(false);
  const { handleLang, translate } = useContext(TranslateContext);
  useEffect(() => {
    return scrollY.onChange((latest) => {
      setIsScrolled(latest > 30)
    })
  }, [scrollY])
  const [isOpen, setOpen] = useState(false)


  const onClick = handleClick => () => {
    setOpen(false)
    handleClick()
  }
  const bgBody = "rgb(255, 255, 255, 0.7)";

  return (
    <motion.nav
      animate={{ backgroundColor: isScrolled ? bgBody : undefined }}
      className={classNames("navigation-bar py-3 w-100", isScrolled ? "text-dark" : "text-light", isScrolled ? '' : 'bg-default')}
    >
      <Container >
        <Row className="align-items-center">
          <Col xs="12" xl="auto" className="d-flex ps-md-3 align-items-center">
            <NavLink to={"/"}>
              <img style={{ height: "3rem" }} src={isScrolled ? sailapi : logo} alt="sail api logo" />
            </NavLink>
            {Boolean(links.length) &&
              <span className="ms-auto d-xl-none">
                <Hamburger toggled={isOpen} toggle={setOpen} size={20} aria-label="menu" />
              </span>
            }
          </Col>
          {links.map(({ text, handleClick }, index) => (
            <Col
              xs="auto"
              className={classNames("d-none d-xl-block", index === 0 ? "ms-auto" : "")}
              key={index}
            >
              <span onClick={handleClick} className="link">
                {translate(text)}
              </span>
            </Col>
          ))}
          <Col xs="auto" className="d-none d-xl-block">
            <Col xs="auto" className="px-3 languages">
              <span onClick={() => handleLang('es')} className="mx-2 px-2" >ES</span>
              <span onClick={() => handleLang('en')}>EN</span>
            </Col>
          </Col>

          <Collapse isOpen={isOpen} className="mx-auto d-xl-none">
            <div className="d-flex flex-column text-center pt-1 pb-3" >
              {links.map(({ text, handleClick }, i) =>
                <React.Fragment key={i}>
                  <span onClick={onClick(handleClick)} className="my-2 link" >
                    {translate(text)}
                  </span>
                </React.Fragment>
              )}

              <div className="d-flex justify-content-center mt-2">
                <Col xs="auto" className="px-3 languages">
                  <span onClick={() => handleLang('es')} className="mx-2 px-2" >ES</span>
                  <span onClick={() => handleLang('en')}>EN</span>
                </Col>
              </div>

            </div>
          </Collapse>
        </Row>
      </Container>
    </motion.nav>
  )
}

export default Navbar;


import { Col, Container, Row } from "react-bootstrap";
import { useContext } from "react";

import { TranslateContext } from "../../hooks/context/translateContext";

import sailapi from "../../media/sailapi.svg";

const Footer = ({ links = [] }) => {
  const { translate } = useContext(TranslateContext);

  return (
    <Container fluid className="px-5 pt-5 footer">
      <Row className="py-5 g-3 justify-content-between align-items-center">
        <Col xs={7} md={9}><img src={sailapi} alt="sail api logo" className="footer-logo" /></Col>
        <Col xs={5} md={3}>
          <Row>
            <h2>{translate('Información')}</h2>
            {links.map(({ text, handleClick }, index) => (
              <Col
                xs={12}
                md={12}
                key={index}
              >
                <span onClick={handleClick} className="footer-link">
                  {translate(text)}
                </span>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="sub-footer mt-5 py-3">
        <Col className="text-light px-0" style={{ fontSize: '1.2em' }}>© SAILAPI, 2024</Col>
      </Row>
    </Container>
  )
}

export default Footer;
import React, { useContext, useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';

import { TranslateContext } from '../hooks/context/translateContext';

const defaultData = {
    email: '',
    name: '',
    phone: '',
    text: '',
}

const Contact = () => {

    const { translate } = useContext(TranslateContext);
    const [disabled,setDisabled] = useState(false);
    const [data, setData] = useState(defaultData)
    const [alert, setAlert] = useState(false);

    const handleInputChange = (event) => {
        setAlert(false);
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const { name, phone, email, text } = data;
        
        fetch('https://api.web3forms.com/submit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              'Nombre': name , 
              'Teléfono': phone,
              'Correo': email,
              'Mensaje':text,
      
              from_name: 'Landing Sail api',
              subject: 'Formulario de contacto',
              replyto: email,
              access_key: process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_W3FORMS_KEY_DEV :
                process.env.REACT_APP_W3FORMS_KEY_PROD
            })
          })
          .then(() => {
            setDisabled(true);
             setAlert(true);
            setData(defaultData)
      
          })
    }

    return (
        <Form
            onSubmit={handleSubmit}
            className="mx-auto w-100 p-4 rounded mr-lg-7"
            style={{ maxWidth: 500, borderWidth: '20px', background: 'rgb(255, 255, 255, 0.7)' }}>

            <FormGroup>
                <Label for="email">{translate('Email')}<span className='text-danger'>*</span></Label>
                <Input required type="email" name="email" value={data.email} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
                <Label for="name">{translate('Nombre completo')}<span className='text-danger'>*</span></Label>
                <Input required type="text" name="name" value={data.name} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
                <Label for="phone">{translate('Teléfono')}<span className='text-danger'>*</span></Label>
                <Input required type="tel" name="phone" value={data.phone} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup>
                <Label for="clubName">{translate('Asunto')}<span className='text-danger'>*</span></Label>
                <Input required type="textarea" name="text" rows={4} value={data.text} onChange={handleInputChange} />
            </FormGroup>

            <div className="d-flex justify-content-center mt-2">
                <Button
                disabled={disabled}
                    type="submit"
                    color="primary"
                    className="rounded w-50"
                >{translate('Enviar')}</Button>
            </div>
            <Alert color="success" isOpen={alert} className="mt-3">
                {"El mensaje ha sido enviado correctamente"}
            </Alert>
        </Form>
    )
}

export default Contact;

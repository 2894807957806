import { useContext } from 'react';
import { Container } from 'react-bootstrap';

import { Stepper, MantineProvider } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faPause, faRobot,faHistory } from '@fortawesome/free-solid-svg-icons';

import { TranslateContext } from '../../../hooks/context/translateContext';

import '@mantine/core/styles.css';

const Section1 = () => {
    const { translate } = useContext(TranslateContext);

    const features = [
        {
            title: translate('Acceso al historial de operaciones'),
           content: (<p><strong>SailApi</strong> {translate('te permite acceder a las órdenes históricas de tu terminal y al historial de transacciones para revisar y analizar todas las operaciones realizadas.')}</p>),
           icon: <FontAwesomeIcon  icon={faHistory}/>
        },
        {
            title: translate('Detección de DrawDown'),
            content: translate('Monitoriza el valor de cada posición abierta en relación con su precio de apertura para identificar DrawDowns significativos que puedan comprometer la rentabilidad de la estrategia.'),
            icon:  <FontAwesomeIcon icon={faChartLine}/>

        },
        {
            title: translate('Pausa de operaciones'),
            content: translate('Detiene temporalmente la apertura de nuevas posiciones en la dirección del DrawDown para evitar mayores pérdidas.'),
            icon:  <FontAwesomeIcon icon={faPause}/>
        },
        {
            title: translate('Optimización dinámica por IA'),
            content: translate('Analiza en tiempo real los datos de los mercados globales, monitoriza las notícias del mercado Forex en tiempo real, y supervisa la dinámica individual de cada uno de los bots de cada cliente nuestro para identificar patrones y tendencias, así como ajustar dinámicamente parámetros internos como el nivel de DrawDown, la activación y duración que debe tener nuestro sistema “Sailing Mode”.'),
            icon:  <FontAwesomeIcon icon={faRobot}/>
        }
    ];

    return (
        <div className="bg-secondary p-5" id="features">
            <h2 className="mb-5 p-2 section-title">{translate('Funcionamiento')}</h2>
            <Container className='w-lg-75 d-flex justify-content-center mt-5'>
                <MantineProvider style={{cursor: 'none'}}>
                    <Stepper orientation="vertical" style={{width: '24rem'}}>
                        {features.map((feature, index) =>
                            <Stepper.Step style={{cursor: 'default',paddingBottom: '40px'}}  label={feature.title} description={feature.content} icon={feature.icon} key={index} />
                        )}
                    </Stepper>
                </MantineProvider>
            </Container>
        </div>
    )
};
export default Section1;
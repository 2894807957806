import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { TranslateWrapper } from "./hooks/context/translateContext";

import General from "./views/layouts/General";
import Home from "./views/pages/Home";
import "./App.css";

function App() {
  return (
    <Router>
      <TranslateWrapper >
      <General>
        <Routes>
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </General>
      </TranslateWrapper>
    </Router>
  );
}

export default App;
